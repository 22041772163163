import React, { useState, useContext } from 'react';
import { Settings } from '@styled-icons/material/Settings';
import { Timer } from '@styled-icons/boxicons-regular/Timer';
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline';
import Time from '~/components/PomodoroDisplay/Time';
import Start from '~/components/PomodoroButtons/Start';
import Pause from '~/components/PomodoroButtons/Pause';
import Reset from '~/components/PomodoroButtons/Reset';
import ModeButton from '~/components/PomodoroButtons/ModeButton';
import SettingsMenu from '~/components/PomodoroSettings/SettingsMenu';
import Audio from '~/components/PomodoroDisplay/Audio';
import TimerState from '~/context/TimerState';

import { Container, Controls, SettingsButtonWrapper } from './styles';

const Pomodoro = ({ showPomodoro, handleShowPomodoro }) => {
  const [showSettings, setShowSettings] = useState(false);

  return (
    <Container className={`pomodoro ${showPomodoro ? 'visible' : ''}`}>
      <SettingsButtonWrapper>
        {!showSettings ? (
          <Settings onClick={() => setShowSettings(!showSettings)} size={10} />
        ) : (
          <Timer onClick={() => setShowSettings(!showSettings)} size={10} />
        )}
        <CloseOutline onClick={handleShowPomodoro} />
      </SettingsButtonWrapper>
      <TimerState>
        {!showSettings ? (
          <div>
            <Time />
            <Controls>
              <>
                <Start />
                <Pause />
                <Reset />
              </>
            </Controls>
            <Controls>
              <ModeButton thisMode="pomodoro" text="Pomodoro" />
              <ModeButton thisMode="shortbreak" text="Short Break" />
              <ModeButton thisMode="longbreak" text="Long Break" />
            </Controls>
          </div>
        ) : (
          <>
            <SettingsMenu />
            <Audio />
          </>
        )}
      </TimerState>
    </Container>
  );
};

export default Pomodoro;
