import React, { useState, useEffect, useRef } from 'react';
import TimerContext from './timerContext';
import { playSound } from '~/components/PomodoroDisplay/Audio';

// Provide app-level state and controls
const TimerState = props => {
  // Local storage
  let storage;

  if (typeof window !== 'undefined') {
    storage = window.localStorage;
  }

  // Remaining time on timer in seconds
  const [time, setTime] = useState(undefined);

  // Bool determines whether timer is counting down
  const [timerRunning, setTimerRunning] = useState(undefined);

  // Default start times for timer
  // Retrieve from storage if possible
  const [defaultTimes, setDefaultTimes] = useState({});

  // Timer can run in 3 modes:
  // 'pomodoro' 'shortbreak' 'longbreak'
  // Useful for obtaining default time value from previous state
  const [mode, setMode] = useState('pomodoro');

  const [longBreakAfter, setLongBreakAfter] = useState();

  // Holds setTimeout object for timer
  const _timer = useRef(false);

  useEffect(() => {
    setTime(storage.getItem('time') || 25 * 60);
    setTimerRunning(storage.getItem('running') === 'true');
    setDefaultTimes({
      pomodoro: storage.getItem('pomodoro') || 25 * 60,
      shortbreak: storage.getItem('shortbreak') || 5 * 60,
      longbreak: storage.getItem('longbreak') || 15 * 60,
    });
    setLongBreakAfter(storage.getItem('longBreakAfter') || 4);
  }, []);

  // Timer effect hook
  useEffect(() => {
    if (timerRunning && time > 0) {
      storage.setItem('running', true);
      _timer.current = setTimeout(() => {
        storage.setItem('time', time - 1);
        setTime(time - 1);
        const timeMinusOne = time - 1;
        const newTitle = `${document.title.substr(
          0,
          document.title.indexOf('|')
        )}| `;
        document.title = `${newTitle} ${`0${Math.floor(
          timeMinusOne / 60
        ).toString()}`.slice(-2)}:${`0${(timeMinusOne % 60).toString()}`.slice(
          -2
        )}`;
      }, 1000);
    } else if (_timer.current) {
      // Stop currently running timer, important when user hits "Stop"
      clearTimeout(_timer.current);
      if (time === 0) {
        playSound();

        switch (mode) {
          case 'pomodoro':
            setLongBreakAfter(longBreakAfter - 1);
            if (longBreakAfter > 0) {
              setMode('shortbreak');
              setTimerRunning(false);
              setTime(defaultTimes.shortbreak);
              storage.setItem('time', defaultTimes.shortbreak);
            } else {
              setMode('longbreak');
              setTimerRunning(false);
              setTime(defaultTimes.longbreak);
              storage.setItem('time', defaultTimes.longbreak);
              setLongBreakAfter(4);
            }
            break;

          case 'shortbreak':
            setMode('pomodoro');
            setTimerRunning(false);
            setTime(defaultTimes.pomodoro);
            storage.setItem('time', defaultTimes.pomodoro);
            break;

          case 'longbreak':
            setLongBreakAfter(4);
            setMode('pomodoro');
            setTimerRunning(false);
            setTime(defaultTimes.pomodoro);
            storage.setItem('time', defaultTimes.longbreak);
            break;

          default:
            break;
        }
      }

      storage.setItem('running', false);
      _timer.current = false;
    }

    return function () {
      clearTimeout(_timer.current);
    };
  }, [timerRunning, time]);

  // Store default times changes
  useEffect(() => {
    storage.setItem('pomodoro', defaultTimes.pomodoro);
    storage.setItem('shortbreak', defaultTimes.shortbreak);
    storage.setItem('longbreak', defaultTimes.longbreak);
    storage.setItem('longBreakAfter', longBreakAfter);

    // eslint-disable-next-line
  }, [defaultTimes]);

  // User clicks one of the mode buttons to change to pomodoro/short break/long break
  const changeMode = modeName => {
    // Prevent updating mode with the same mode
    if (mode !== modeName) {
      setMode(modeName);
      setTimerRunning(false);
      setTime(defaultTimes[modeName]);
      const newTitle = `${document.title.substr(
        0,
        document.title.indexOf('|')
      )}| `;
      document.title = `${newTitle} ${`0${Math.floor(
        defaultTimes[modeName] / 60
      ).toString()}`.slice(-2)}:${`0${(
        defaultTimes[modeName] % 60
      ).toString()}`.slice(-2)}`;
    }
  };

  return (
    <TimerContext.Provider
      value={{
        _timer,
        time,
        timerRunning,
        mode,
        defaultTimes,
        setTime,
        setTimerRunning,
        setMode,
        setDefaultTimes,
        changeMode,
      }}
    >
      {props.children}
    </TimerContext.Provider>
  );
};

export default TimerState;
