export const emailMessage = (site) => `Hi,
  
Go outside without leaving your home!

Check this out: ${site}

I hope you enjoy it!

Outside Simulator
`;

export const title = (socialTitle) => socialTitle || 'Outside Simulator';

export const description = (url = true, socialDescription) => socialDescription
  || `Where would you like to be? ${
    url ? 'Visit: https://OutsideSimulator.com' : ''
  }`;
