import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  h1 {
    margin: 10px 0 20px;
  }
`;
