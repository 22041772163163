import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { isMobile } from 'react-device-detect';
import Fullscreen from 'react-full-screen';
import { useLocation } from '@reach/router';

import GlobalStyles from '~/styles/global';

import SEO from '~/components/seo.js';
import BackgroundVideo from '~/components/BackgroundVideo';
import VideoHeader from '~/components/VideoHeader';
import MobileNotice from '~/components/MobileNotice';

const BlogPost = ({ data, pageContext }) => {
  const [playing, setPlaying] = useState(!isMobile);
  const [showNotice, setShowNotice] = useState(isMobile);
  const [isFull, setIsFull] = useState(false);
  const post = data.markdownRemark.frontmatter;
  const location = useLocation();
  const [muted, setMuted] = useState(!location.state?.prevUrl);

  const hideHeader = location.search == '?no-header';

  const {
    next: {
      fields: { slug },
    },
  } = pageContext;

  const goFull = () => {
    setIsFull(!isFull);
  };

  const handlePlay = () => {
    setPlaying(true);
    setShowNotice(false);
  };

  return (
    <>
      <SEO
        title={post.place}
        description={`${post.place} - ${post.city} - ${post.country}`}
        image={post.featuredImage.childImageSharp.fixed.src}
      />
      <GlobalStyles />
      <MobileNotice
        handlePlay={handlePlay}
        playing={playing}
        showNotice={showNotice}
      />
      <Fullscreen enabled={isFull} onChange={(isFull) => setIsFull(isFull)}>
        <BackgroundVideo video={post.video} playing={playing} muted={muted} />
        {hideHeader || (
          <VideoHeader
            next={slug}
            goFull={goFull}
            isFull={isFull}
            channelTitle={post.channelTitle}
            video={post.video}
            muted={muted}
            setMuted={setMuted}
            playing={playing}
          />
        )}
      </Fullscreen>
    </>
  );
};

export const query = graphql`
  query Post($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        place
        city
        country
        video
        channelTitle
        channelUrl
        featuredImage {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    }
  }
`;

export default BlogPost;
