import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;
  transition: 0.5s all;
  transform: ${({ showMenu }) => (showMenu ? 'translateY(0)' : 'translateY(-100%)')};
  width: 100%;

  &.video-footer {
    position: absolute;
    top: 100%;
    transform: ${({ showMenu }) => (showMenu ? 'translateY(-100%)' : 'translateY(0%)')};

    > div > span,
    > div > a {
      width: initial;
    }
  }

  &.mute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: initial;
    opacity: ${({ showMenu }) => (showMenu ? '1' : '0')};
    
    &.middle {
      > div > span > svg {
        margin-bottom: 10px;
        max-width: 100px;
        max-height: 100px;
      }

      > div > span,
      > div > a {
        width: initial;
        flex-direction: column;
        height: 200px;
        width: 200px;
      }
    }

    &.corner {
      position: absolute;
      top: 100%;
      left: 0;
      transform: ${({ showMenu }) => (showMenu ? 'translateY(-100%)' : 'translateY(0%)')};
    }

    > div > span,
    > div > a {
      width: initial;
      flex-direction: row;
      height: initial;
      width: initial;
    }
  }

  > div {
    display: flex;
    flex: 1;
    cursor: pointer;
    justify-content: center;

    &.chrome {
      @media (max-width: 767px) {
        display: none;
      }
    }

    &:first-of-type {
      justify-content: flex-start;
    }

    &:last-of-type {
      justify-content: flex-end;
    }

    > a,
    > span {
      text-transform: uppercase;
      background: #00000070;
      padding: 5px 10px;
      opacity: 0.8;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 96%;
      height: 50px;

      &:hover {
        opacity: 1;
      }

      > span {
        @media (max-width: 900px) {
          font-size: 13px;
        }
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }

  svg {
    max-width: 40px;
    color: #fff;
    min-width: 40px;
    max-height: 30px;
  }

  .social-share {
    position: absolute;
    background: #ffffff8a;
    top: 70px;

    &::before {
      content: '';
      position: absolute;
      left: calc(50% - 20px);
      top: -20px;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #ffffff8a;
    }
  }

  .pomodoro {
    position: absolute;
    background: #00000070;
    top: 70px;

    &::before {
      content: '';
      position: absolute;
      left: calc(50% - 20px);
      top: -20px;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #00000070;
    }

    @media (max-width: 767px) {
      left: 50%;
      transform: translateX(-50%);

      &:before {
        display: none;
      }
    }
  }
`;

export const Overlay = styled.div`
  top: 0;
  color: #fff;
  height: 100vh;
  width: 100vw;
  cursor: ${({ showMenu }) => (showMenu ? 'normal' : 'none')};
  position: fixed;
`;
