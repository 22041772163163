import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  color: #fff;

  button {
    background: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    transition: all 0.3s;

    &:hover {
      background-color: #00f3ffbd;
    }
  }
`

export const Lengths = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  p {
    margin-bottom: 0;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  input {
    width: 90%;
    color: #000;
    padding: 10px;
    margin-top: 10px;
    text-align: center;
  }
`

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  p {
    margin-bottom: 10px;
  }
`

export const SoundContainer = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const Footer = styled.section`
  display: flex;
  width: 100%;
  padding: 10px;
  border-top: 1px solid #fff;
  margin-top: 20px;
  padding-bottom: 0;
  align-items: center;
  justify-content: center;
`
