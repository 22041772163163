import styled from "styled-components"

export const Container = styled.div`
  width: 300px;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  svg {
    min-height: 40px;
  }

  button {
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
`
