import React, { useState } from "react"

import Logo from "~/images/logo.svg"
import { Container } from "./styles"

const MobileNotice = ({ handlePlay, showNotice }) => {
  return (
    <>
      {showNotice && (
        <Container>
          <img src={Logo} alt="Outside Simulator" />
          <p>This works best on desktop</p>
          <button onClick={handlePlay}>Click Here to Play</button>
        </Container>
      )}
    </>
  )
}

export default MobileNotice
