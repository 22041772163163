import React, { useContext } from 'react';
import TimerContext from '~/context/timerContext';

import { Mode } from './styles';
// Pomodoro, Short Break, Long Break buttons
const ModeButton = ({ thisMode, text }) => {
  const timerContext = useContext(TimerContext);
  const { mode, changeMode } = timerContext;
  return (
    <Mode
      type="button"
      className={`${
        mode === thisMode ? 'active' : ''
      }`}
      onClick={() => {
        changeMode(thisMode);
      }}
    >
      {text}
    </Mode>
  );
};

export default ModeButton;
