import styled from "styled-components"

export const VideoBackground = styled.div`
  background: #000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
  max-height: 100vh;
  overflow: hidden;
  max-height: 100%;
`

export const VideoForeground = styled.div`
  &,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    @media (min-aspect-ratio: 16/9) {
      height: 300%;
      top: -100%;
    }

    @media (max-aspect-ratio: 16/9) {
      width: 300%;
      left: -100%;
    }

    @media (max-aspect-ratio: 16/9) and (min-aspect-ratio: 16/9) {
      height: 100%;
      top: 0;
    }
  }
`
