import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: black;
  position: fixed;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  flex-direction: column;

  button {
    width: 100%;
    max-width: 200px;
    padding: 20px;
    background: white;
    color: #000;
    text-transform: uppercase;
    font-size: 20px;
  }

  img {
    margin-bottom: 20px;
  }
`
