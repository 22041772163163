import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,

  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { emailMessage, title, description } from './socialContent';

import { Container } from './styles';

const siteUrl = 'https://OutsideSimulator.com';
const SocialShare = () => {
  const {
    file: {
      childImageSharp: {
        fixed: { src },
      },
    },
  } = useStaticQuery(graphql`
    query Image {
      file(relativePath: { eq: "cover.jpg" }) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
    }
  `);
  return (
    <Container className="social-share">
      <FacebookShareButton
        url={siteUrl}
        quote={description()}
        hashtag="#stayhome"
      >
        <FacebookIcon />
      </FacebookShareButton>
      <EmailShareButton
        url={siteUrl}
        subject="Outside Simulator"
        body={emailMessage(siteUrl)}
      >
        <EmailIcon />
      </EmailShareButton>
      <LinkedinShareButton
        url={siteUrl}
        title={title()}
        summary={description()}
      >
        <LinkedinIcon />
      </LinkedinShareButton>
      <PinterestShareButton
        url={siteUrl}
        description={description()}
        image={src}
      >
        <PinterestIcon />
      </PinterestShareButton>
      <RedditShareButton url={siteUrl}>
        <RedditIcon title={`${title()} - ${description(false)}`} />
      </RedditShareButton>
      <TelegramShareButton
        url={siteUrl}
        title={`${title()} - ${description()}`}
      >
        <TelegramIcon />
      </TelegramShareButton>
      <TwitterShareButton
        url={siteUrl}
        title={`${title()} - ${description(false)}`}
        hashtags={['stayhome', 'outsidesimulator']}
      >
        <TwitterIcon />
      </TwitterShareButton>
      <WhatsappShareButton
        url={siteUrl}
        title={`${title()} - ${description(false)}`}
      >
        <WhatsappIcon />
      </WhatsappShareButton>
    </Container>
  );
};

export default SocialShare;
