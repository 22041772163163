import styled from 'styled-components';

export const Container = styled.div`
  width: 300px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  color: #fff;
  position: relative;
  display: none;

  &.visible {
    display: block;
  }

  svg {
    min-height: 40px;
  }

  button {
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
`;

export const Controls = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
`;

export const SettingsButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 0 10px;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  svg {
    min-width: 20px;
    min-height: 20px;
    cursor: pointer;
  }
`;
