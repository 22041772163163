import React, { useContext } from 'react';
import { Reset } from '@styled-icons/boxicons-regular/Reset';
import TimerContext from '~/context/timerContext';

import { Button } from './styles';

const ResetButton = () => {
  let storage;

  if (typeof window !== 'undefined') {
    storage = window.localStorage;
  }

  const timerContext = useContext(TimerContext);
  const { setTime, setTimerRunning, mode, defaultTimes } = timerContext;

  const handleReset = () => {
    setTime(defaultTimes[mode]);
    setTimerRunning(false);
    storage.setItem('time', defaultTimes[mode]);
    const newTitle = `${document.title.substr(
      0,
      document.title.indexOf('|')
    )}| `;
    document.title = `${newTitle} ${`0${Math.floor(
      defaultTimes[mode] / 60
    ).toString()}`.slice(-2)}:${`0${(
      defaultTimes[mode] % 60
    ).toString()}`.slice(-2)}`;
  };

  return (
    <Button type="button" onClick={handleReset}>
      <Reset />
    </Button>
  );
};

export default ResetButton;
