import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { trackCustomEvent, OutboundLink } from 'gatsby-plugin-google-analytics';
import { ArrowBackOutline } from '@styled-icons/evaicons-outline/ArrowBackOutline';
import { ArrowForwardOutline } from '@styled-icons/evaicons-outline/ArrowForwardOutline';
import { Fullscreen } from '@styled-icons/material-rounded/Fullscreen';
import { FullscreenExit } from '@styled-icons/material-rounded/FullscreenExit';
import { Share } from '@styled-icons/evaicons-solid/Share';
import { CoffeeTogo } from '@styled-icons/boxicons-regular/CoffeeTogo';
import { Timer } from '@styled-icons/boxicons-regular/Timer';
import { Chrome } from '@styled-icons/boxicons-logos/Chrome';
import { VolumeMute } from '@styled-icons/boxicons-regular/VolumeMute';
import { VolumeFull } from '@styled-icons/boxicons-regular/VolumeFull';

import SocialShare from '~/components/SocialShare';
import Pomodoro from '~/components/Pomodoro';
import sound from '~/components/PomodoroDisplay/alarm.mp3';

import { Container, Overlay } from './styles';

const Header = ({
  next, goFull, isFull, channelTitle, video, muted, setMuted,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showPomodoro, setShowPomodoro] = useState(false);
  const [timeOut, setTimeOut] = useState('');
  const [isChrome, setIsChrome] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsChrome(
        !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime),
      );
    }
  }, []);

  const handleMouseMove = () => {
    if (!showMenu) {
      setShowMenu(true);
      setTimeOut(
        setTimeout(() => {
          setShowMenu(false);
          setShowShare(false);
        }, 5000),
      );
    }
  };

  const handleShowShare = () => {
    setShowShare(!showShare);

    if (!showShare) {
      trackCustomEvent({
        category: 'Video Header',
        action: 'Show',
        label: 'Share',
      });
    }
  };

  const handleShowPomodoro = () => {
    setShowPomodoro(!showPomodoro);

    if (!showPomodoro) {
      trackCustomEvent({
        category: 'Video Header',
        action: 'Show',
        label: 'Pomodoro',
      });
    }

    clearTimeout(timeOut);

    if (showPomodoro) {
      setTimeOut(
        setTimeout(() => {
          setShowMenu(false);
          setShowShare(false);
        }, 1000),
      );
    }
  };

  return (
    <Overlay onMouseMove={handleMouseMove} showMenu={showMenu}>
      <audio src={sound} type="audio/wav" />
      <Container className="video-header" showMenu={showMenu}>
        <div>
          <Link to="/">
            <ArrowBackOutline />
            <span>Back to Website</span>
          </Link>
        </div>

        <div onClick={goFull}>
          <span>
            {isFull ? (
              <>
                <FullscreenExit />
                <span>Close Full Screen</span>
              </>
            ) : (
              <>
                <Fullscreen />
                <span>Full Screen</span>
              </>
            )}
          </span>
        </div>

        <div onClick={handleShowShare}>
          <span>
            <Share />
            <span>Share</span>
          </span>
          {showShare && <SocialShare className="social-share" />}
        </div>

        <div>
          <OutboundLink
            target="_blank"
            href="https://www.buymeacoffee.com/andrevitorio"
          >
            <CoffeeTogo />
            <span>Buy Me a Coffee</span>
          </OutboundLink>
        </div>

        {isChrome && (
          <div className="chrome">
            <OutboundLink
              target="_blank"
              href="https://chrome.google.com/webstore/detail/outside-simulator/lemblgpmokabdmjphfehokigmpeobefc"
            >
              <Chrome />
              <span>Chrome Extension</span>
            </OutboundLink>
          </div>
        )}

        <div className="chrome">
          <span role="presentation" onClick={handleShowPomodoro}>
            <Timer />
            <span>Pomodoro</span>
          </span>
          <Pomodoro
            showPomodoro={showPomodoro}
            handleShowPomodoro={handleShowPomodoro}
          />
        </div>

        <div>
          <Link to={next}>
            <span>Next Location </span>
            <ArrowForwardOutline />
          </Link>
        </div>
      </Container>
      {channelTitle && (
        <Container className="video-footer" showMenu={showMenu}>
          <div>
            <OutboundLink target="_blank" href={video}>
              Video By
              {' '}
              {channelTitle}
            </OutboundLink>
          </div>
        </Container>
      )}

      <Container className={muted ? 'mute middle' : 'mute corner'} showMenu={showMenu}>
        <div>
          <span role="presentation" onClick={() => setMuted(!muted)}>
            {muted ? <VolumeMute size={100} /> : <VolumeFull />}
            {muted ? 'Click to Unmute' : 'Click to Mute'}
          </span>
        </div>
      </Container>
    </Overlay>
  );
};

export default Header;
